import UrlUtil from "../../core/internal/util/UrlUtil"
import { SplitUrlConfigDto } from "../../core/internal/workspace/dto"
import { SplitUrlContext } from "./SplitUrlContext"
import { SplitUrlConfig, SplitUrlRedirectType } from "../../core/internal/model/model"

export class SplitUrlResolver {
  static readonly SPLIT_URL_CONFIG_KEY = "redirectUrl"

  resolveOrNull(context: SplitUrlContext): URL | null {
    try {
      const splitUrlConfigDto: SplitUrlConfigDto = JSON.parse(
        context.decision.get(SplitUrlResolver.SPLIT_URL_CONFIG_KEY, "")
      )
      const splitUrlConfig = SplitUrlConfig.fromDto(splitUrlConfigDto)

      if (!splitUrlConfig.needRedirect) {
        return null
      }

      return this.resolveByRedirectType(splitUrlConfig.redirectType, splitUrlConfig.redirectUrl)
    } catch (e) {
      return null
    }
  }

  private resolveByRedirectType(type: SplitUrlRedirectType | null, redirectUrl: string | null): URL | null {
    if (!redirectUrl) {
      return null
    }

    switch (type) {
      case SplitUrlRedirectType.MANUAL:
        return this.resolveManualRedirectUrl(redirectUrl)
      default:
        return null
    }
  }

  private resolveManualRedirectUrl(redirectUrl: string) {
    return UrlUtil.tryParse(redirectUrl) ?? null
  }
}
